import React from "react";
import { FaTelegram, FaGithub, FaLinkedin } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://github.com/RakhimB" className="github_social">
        <FaGithub />
      </a>
    </div>

    <div>
      <a
        href="https://www.linkedin.com/in/bakhrom-rakhimov-180a91211/"
        className="linkedin_social"
      >
        <FaLinkedin />
      </a>
    </div>

    <div>
      <a href="https://t.me/XFREEX13" className="telegram_social">
        <FaTelegram />
      </a>
    </div>
  </div>
);

export default SocialMedia;
